.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    justify-content: center;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    font-style: italic;
    text-transform: uppercase;
}

.program-cateogries{
    display: flex;
    gap: 1rem;
}
.cateogry{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: gray;
    color: white;
    justify-content: space-between;
    border-radius: 20px;
    transition: var(--transition);
}
.cateogry>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.cateogry>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.cateogry>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}
.join-now{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.join-now>img{
    width: 1rem;
}
.cateogry:hover{
    background: var(--planCard);
    cursor: pointer;   
}


/*------------------- media query for small devices ------------------------*/

@media screen and (max-width:768px) {
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-cateogries{
        flex-direction: column;
    }
}